import * as React from 'react'

import styles from './Order.module.scss'

interface Props {
  id: string
  date: string
  status: string
  items: object[]
  price: any
}

class Order extends React.Component<Props> {
  state = {
    active: false,
  }

  toggleOrder = () => {
    this.setState({ active: !this.state.active })
  }

  render() {
    const { id, date, status, items, price } = this.props
    const { active } = this.state
    let statusClass = styles.circle
    switch (status) {
      case 'Success':
        statusClass = [styles.success, styles.circle].join(' ')
        break
      case 'Archived':
        statusClass = [styles.archived, styles.circle].join(' ')
        break
      case 'Cancelled':
        statusClass = [styles.cancelled, styles.circle].join(' ')
        break
      default:
        break
    }
    const orderClass = active
      ? [styles.order, styles.orderActive].join(' ')
      : styles.order

    return (
      <div className={orderClass}>
        <div className={styles.orderInfo} onClick={this.toggleOrder}>
          <div className={styles.status}>
            <div className={statusClass} /> {status}
          </div>
          <div>{date}</div>
          <div className={styles.id}>{id}</div>
        </div>
        <div className={styles.orderDetails}>
          {items &&
            items.map((item: any, index: number) => (
              <div key={index} className={styles.orderItem}>
                <div className={styles.itemName}>
                  <div>{item.name}</div>
                  <div>{item.size}</div>
                </div>
                <div className={styles.itemPrice}>
                  {item.quantity} x {item.price}
                </div>
              </div>
            ))}
          <div className={styles.total}>
            <div className={styles.totalRow}>
              <span>Total:</span>
              <span>{price.total}</span>
            </div>
            <div className={styles.totalRow}>
              <span>Shipping:</span>
              <span>{price.shipping}</span>
            </div>
            <div className={styles.totalRow}>
              <strong>Grand total:</strong>
              <strong>{price.grandTotal}</strong>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Order
