import * as React from 'react'
import { inject, observer } from 'mobx-react'

import styles from 'src/components/Login/Login.module.scss'
import Input from 'src/components/UI/Input/Input'
import PrimaryButton from 'src/components/UI/Button/PrimaryButton'

type Props = {
  cart?: any
}

@observer
class Login extends React.Component<Props> {
  state = {
    error: false,
    email: '',
    password: '',
  }

  handleInputChange = (e: any) => {
    let state = this.state
    state['error'] = false
    state[e.target.name] = e.target.value
    this.setState(state)
  }

  handleSubmit = async (e: any) => {
    // const { cart } = this.props
    // const { email, password } = this.state
    // e.preventDefault()
    // e.stopPropagation()
    // if (cart) {
    //   const status = await cart.loginUser(email, password)
    //   if (!status) {
    //     this.setState({ error: true })
    //   }
    // }
  }

  render() {
    const { error } = this.state
    const loginClassname = error
      ? [styles.loginForm, styles.loginError].join(' ')
      : styles.loginForm
    return (
      <div className="container">
        <div className={styles.loginWrapper}>
          <h1>Login</h1>
          <form className={loginClassname} onSubmit={this.handleSubmit}>
            <Input
              onChange={this.handleInputChange}
              type="email"
              name="email"
              placeholder="Email"
            />
            <Input
              onChange={this.handleInputChange}
              type="password"
              name="password"
              placeholder="Password"
            />
            <PrimaryButton>{'Log in'}</PrimaryButton>
          </form>
        </div>
      </div>
    )
  }
}

export default Login
