import * as React from 'react'

import styles from 'src/components/Login/Login.module.scss'
import Input from 'src/components/UI/Input/Input'
import PrimaryButton from 'src/components/UI/Button/PrimaryButton'
import Login from 'src/components/Login/Login'
import Customer from 'src/components/Customer/Customer'

const customer = {
  name: 'Gustav Lundin',
  orders: [
    {
      id: '#1298134',
      status: 'Cancelled',
      date: '2017-05-03 13:33:56',
      items: [
        {
          name: 'Olsson Toe Panel Socks',
          size: 'One size',
          price: '99.00 SEK',
          quantity: '2',
        },
        {
          name: 'Olsson Toe Panel Socks',
          size: 'One size',
          price: '99.00 SEK',
          quantity: '2',
        },
      ],
      price: {
        total: '188 SEK',
        shipping: '49 SEK',
        grandTotal: '237 SEK',
      },
    },
    {
      id: '#1298134',
      status: 'Success',
      date: '2017-05-03 13:33:56',
      items: [
        {
          name: 'Olsson Toe Panel Socks',
          size: 'One size',
          price: '99.00 SEK',
          quantity: '2',
        },
        {
          name: 'Olsson Toe Panel Socks',
          size: 'One size',
          price: '99.00 SEK',
          quantity: '2',
        },
      ],
      price: {
        total: '188 SEK',
        shipping: '49 SEK',
        grandTotal: '237 SEK',
      },
    },
    {
      id: '#1298134',
      status: 'Archived',
      date: '2017-05-03 13:33:56',
      items: [
        {
          name: 'Olsson Toe Panel Socks',
          size: 'One size',
          price: '99.00 SEK',
          quantity: '2',
        },
        {
          name: 'Olsson Toe Panel Socks',
          size: 'One size',
          price: '99.00 SEK',
          quantity: '2',
        },
      ],
      price: {
        total: '188 SEK',
        shipping: '49 SEK',
        grandTotal: '237 SEK',
      },
    },
  ],
}

class CustomerPage extends React.Component {
  render() {
    const isLoggedIn = false
    return isLoggedIn ? <Customer customer={customer} /> : <Login />
  }
}

export default CustomerPage
